@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    @apply relative font-default;
  }

  .popover-btn {
    @apply w-[39px] h-[39px] p-2.5 ml-2 rounded-full bg-fb-popover flex justify-center items-center cursor-pointer active:bg-fb-active;
  }

  .popover-panel {
    @apply w-[300px] p-4 bg-fb-header rounded-lg shadow-lg absolute right-0 top-12 border border-fb-input lg:w-[360px];
  }

  .entry {
    @apply hover:shadow-2xl hover:cursor-pointer;
  }

  body {
    @apply bg-lightColor h-[100vh] w-[100vw] flex flex-col;
  }

  .header {
    @apply bg-defaultColor w-full h-[10%] text-white font-bold  flex flex-col justify-center items-center text-sm sm:text-xs  md:text-base lg:text-xl;
  }

  .logo {
    @apply absolute top-[50%] translate-y-[-50%] left-2 h-[70%] sm:h-[80%];
  }

  .main {
    @apply w-full h-[80%] flex flex-col sm:flex-row justify-evenly  items-center;
  }

  .card {
    @apply rounded-[10%] h-[40%] w-[70%]   sm:h-[80%] sm:w-[40%]  bg-white hover:shadow-2xl;
  }

  .footer {
    @apply bg-defaultColor w-full h-[15%] flex flex-col md:flex-row text-white font-bold pl-2 pr-2 md:justify-evenly justify-start;
  }
}
[v-cloak] {
  display: none;
}
